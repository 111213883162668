<template>
  <AppPage hide-bottom-bar container appBarColor="transparent">
    <template v-slot:app-bar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <p class="subtitle-2 mb-0">
        {{ $vuetify.lang.t("$vuetify.step_2_of_2") }}
      </p>
    </template>

    <div class="auth-container">
      <div>
        <h2>{{ $vuetify.lang.t("$vuetify.create_account") }}</h2>
        <p class="subtitle-1">{{ $vuetify.lang.t("$vuetify.password") }}</p>

        <v-form ref="form" class="pt-3">
          <v-text-field
            outlined
            v-model="password"
            name="some-field"
            class="rounded-lg"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            :rules="[rules.required, rules.minPasswordLength]"
            :label="$vuetify.lang.t('$vuetify.password')"
          ></v-text-field>

          <v-text-field
            v-model="password_confirmation"
            name="some-field-2"
            class="rounded-lg"
            outlined
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :rules="[rules.required, rules.minPasswordLength]"
            :label="$vuetify.lang.t('$vuetify.confirm_password')"
          ></v-text-field>
        </v-form>
      </div>

       <label for="terms" class="terms-label">
            <v-checkbox v-model="terms" hide-details>
              <template v-slot:label>
                <p>
                  {{ $vuetify.lang.t("$vuetify.terms_check_label") }}
                  <router-link
                    to="/terms-conditions"
                    class="subtitle-2 text-primary mt-3 d-inline-block"
                    >{{
                      $vuetify.lang.t("$vuetify.terms_and_conditions")
                    }}</router-link
                  >.
                </p>
              </template>
            </v-checkbox>
          </label>

      <div>
        <app-button
          class="mt-3"
          @click="createAccount"
          :disabled="isDisabled"
          :loading="loading"
          >{{ $vuetify.lang.t("$vuetify.create_account") }}</app-button
        >
        <div class="text-center">
          <p class="text-center grey--text text--darken-2 mt-4 mb-0">
            {{ $vuetify.lang.t("$vuetify.already_have_a_account") }}
            <router-link to="/login" class="text-decoration-none">{{
              $vuetify.lang.t("$vuetify.login")
            }}</router-link>
          </p>
        </div>
      </div>
    </div>

    <img contain src="@/assets/images/paws-top.png" class="paws-top" />
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const userModule = createNamespacedHelpers("user");
import { required, minPasswordLength } from "@/helpers/form-rules";

export default {
  name: "register-2",
  data() {
    return {
      show1: false,
      show: false,
      terms: false,
    };
  },
  computed: {
    ...userModule.mapState(["loading"]),
    ...userModule.mapGetters(["GET_USER_KEY"]),
    rules() {
      return {
        required,
        minPasswordLength,
      };
    },
    password: {
      get() {
        return this.GET_USER_KEY("password");
      },
      set(value) {
        this.SET_USER_FIELD({ key: "password", value });
      },
    },
    password_confirmation: {
      get() {
        return this.GET_USER_KEY("password_confirmation");
      },
      set(value) {
        this.SET_USER_FIELD({ key: "password_confirmation", value });
      },
    },
    isDisabled() {
      const { password, password_confirmation, terms } = this;
      if (!password || !password_confirmation) return true;
      else if (password !== password_confirmation) return true;
      else if (!terms) return true;
      return false;
    },
  },
  methods: {
    ...userModule.mapMutations(["SET_USER_FIELD"]),
    ...userModule.mapActions(["REGISTER_USER"]),
    async createAccount() {
      const result = await this.REGISTER_USER();
      if (!result) return;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  min-height: calc(100vh - 56px - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.paws-top {
  position: absolute;
  top: -65px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
